
import ApiRequestService from '@/services/ApiRequestService'
import SnippetsService from '@/services/SnippetsService'
import StorageService, { LANGUAGE_KEY } from '@/services/StorageService'
import Multiselect from "vue-multiselect";
import { DataEmitterService } from "@/services/DataEmiterService";
import Editor from "@tinymce/tinymce-vue";
import UrlUtil from '@/utils/UrlUtil'
import TranslationWrapper from '@/layouts/components/TranslationWrapper.vue'
import TranslationUtil from '@/utils/TranslationUtil'

export default {

  components: {
    Multiselect,
    TranslationWrapper,
    'editor': Editor
  },
  data() {
    let snippets = SnippetsService.getSnippetsByNamespaceAndLanguage( 'Users', StorageService.getFromStorage( LANGUAGE_KEY ) )

    return {
      translations: {},
      uploadedMapSvgFile: null,
      productsSnippet: snippets ?? {},
      productsList: [],
      paginatedProductsList: [],
      paginatedSearchedProductsList: [],
      productsPaginatedList: [],
      rolesList: [],
      productsListUntouched: [],
      searchedData: [],
      model: +sessionStorage.getItem( 'model' ) ?? 0,
      searchField: '',
      addOrEditSpace: '',
      newType: '',
      currentSpace: {
        id: '',
        name: '',
        description: '',
        qrCode: '',
        floorId: null,
        productId: null,
        mapPosition: null,
        maxNumberOfParticipants: null,
        isBookable: true,
        isInvitational: true,
        isEventCapable: true,
        photos: [],
        services: [],
        photoIds: [],
      },
      defaultSpace: {
        id: '',
        name: '',
        description: '',
        qrCode: '',
        floorId: null,
        productId: null,
        mapPosition: null,
        maxNumberOfParticipants: null,
        isBookable: true,
        isInvitational: true,
        isEventCapable: true,
        photos: [],
        services: [],
        photoIds: [],
      },
      dialog: false,
      spaceDialog: false,
      dialogDelete: false,
      dialogDeleteSpace: false,
      dialogDeletePhoto: false,
      dialogCarousel: false,
      dialogUpgrade: false,
      submitted: false,
      globalError: true,
      spaceSubmitted: false,
      mobileNavBar: false,
      enterNewType: false,
      productId: this.$route.params.id,
      editedIndex: -1,
      toDeleteSpace: {},
      editedItem: {
        id: '',
        name: '',
        description: '',
        type: '',
        brand: '',
        floors: [],
        facilities: [],
        photos: [],
        photoIds: []
      },
      defaultItem: {
        id: '',
        name: '',
        description: '',
        type: '',
        brand: '',
        floors: [],
        facilities: [],
        photos: [],
        photoIds: []
      },
      timeRegex: /^([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/,
      multiSelectRoles: [],
      carouselPhotos: [],
      sortBy: '',
      editedPrefix: '',
      sortDesc: true,
      customSort( items, index, isDescending ) {

        if ( index[0] !== undefined ) {
          if ( index[0] === 'createDate' || index[0] === 'updateDate' ) {
            if ( this.sortDesc ) {
              return items.sort( ( a, b ) => new Date( b[index] ) - new Date( a[index] ) )
            } else {
              return items.sort( ( a, b ) => new Date( a[index] ) - new Date( b[index] ) )
            }
          } else if ( index[0] === '' || index[0] === 'id' ) {

            if ( this.sortDesc ) {
              return items.sort( ( a, b ) => b.id - a.id )
            } else {
              return items.sort( ( a, b ) => a.id - b.id )
            }

          } else if ( index[0] && index[0]?.includes( '.' ) ) {
            let i = index[0].split( '.' )[0]

          } else {

            if ( isDescending[0] === true ) {
              return items.sort( ( a, b ) => a[index]?.toLowerCase().localeCompare( b[index]?.toLowerCase() ) )
            } else if ( isDescending[0] === false ) {
              return items.sort( ( a, b ) => b[index]?.toLowerCase().localeCompare( a[index]?.toLowerCase() ) )
            }

          }
        } else {

          if ( isDescending[0] === true ) {
            return items.sort( ( a, b ) => a[index]?.toLowerCase().localeCompare( b[index]?.toLowerCase() ) )
          } else if ( isDescending[0] === false ) {
            return items.sort( ( a, b ) => b[index]?.toLowerCase().localeCompare( a[index]?.toLowerCase() ) )
          }

        }

        return items
      },
      errorKey: '',
      toDeletePhotoIndex: '',
      pagesCount: 1,
      currentPage: 1,
      disableKeyField: true,
      oldProduct: {},
      addressesDataList: [],
      showSpaces: [],
      productSpaces: [],
      productServices: [],
      facilities: [],
      types: [],
      selectedTypeId: '',
      selectedFacilities: [],
      chips: [],
      chipsNameList: [],
      oldChips: [],
      eventsListHasBeenEdited: false,
    }
  },

  computed: {

    scrollbarTheme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light';
    },

    defaultSpaceImage() {
      return require( '../../assets/images/facilities/no-image.svg' );
    },

    headers() {
      return [
        { text: this.productsSnippet.id, value: 'id', width: '5%' },
        { text: this.productsSnippet.name, value: 'name', width: '15%' },
        { text: this.productsSnippet.description, value: 'description', width: '15%' },
        { text: this.productsSnippet.isEventCapable, value: 'isEventCapable', width: '10%' },
        { text: this.productsSnippet.isInvitational, value: 'isInvitational', width: '10%' },
        { text: this.productsSnippet.isBookable, value: 'isBookable', width: '10%' },
        {
          text: this.productsSnippet.maxNumberOfParticipants,
          value: 'maxNumberOfParticipants',
          width: '10%'
        },
        { text: this.productsSnippet.mapPosition, value: 'mapPosition', width: '10%' },
        { text: this.productsSnippet.actions, value: 'actions', width: '10%', sortable: false },
      ]
    },
  },

  watch: {

    spaceDialog( val ) {
      val || this.closeSpaceDialog()
    },

    model( val ) {
      sessionStorage.setItem( 'model', val );
    },


    dialogCarousel( val ) {
      val || this.closeDialogCarousel()
    },

    dialogDelete( val ) {
      val || this.closeDelete()
    },
    dialogDeletePhoto( val ) {
      val || this.closeDelete()
    },

    dialogUpgrade( val ) {
      val || this.closeUpgrade()
    },

  },

  beforeRouteLeave( to, from, next ) {
    sessionStorage.removeItem( 'model' );

    DataEmitterService.$emit( 'route-change' );

    if ( this.$route.path.includes( 'product-details' ) ) {
      return next();
    }

    StorageService.deleteProductFromStorage();
    next()
  },

  async mounted() {
    await this.getFacilitiesApi();
    await this.getProductApi();
    await this.getProductTypesApi();

    this.loading = false;
  },

  methods: {

    openOrCloseNewTypeWrite() {
      this.enterNewType = !this.enterNewType;
    },

    openAddNewSpaceDialog( floorId ) {
      this.spaceDialog = true;
      this.currentSpace.floorId = floorId;
    },

    setSpacePhotos( files ) {
      if ( files ) {
        this.currentSpace.photoIds = files;
      }
    },


    closeSpaceDialog() {
      this.spaceDialog = false;
      this.spaceSubmitted = false;
      this.submitted = false;
      this.currentSpace = this.getObjClone( this.defaultSpace );
      this.errorKey = '';
      // this.$refs.spaceFileupload.reset();
    },

    deletePhoto( photoIndex ) {
      event.stopPropagation();
      this.toDeletePhotoIndex = photoIndex;
      this.dialogDeletePhoto = true;
    },

    async deleteProductPhoto() {
      let selected = this.editedItem.photos.splice( this.toDeletePhotoIndex, 1 );
      await ApiRequestService.deleteRequest( `api/facility-photos/product-photos/${ selected[0].id }` );
      this.closeDelete();

      if (this.editedItem.photos.length && !this.editedItem.photos.find(item => item.isMain)) {
        await this.selectAsProductMainPhoto(0);
      }
    },

    async deleteProductSpace() {
      if ( !this.toDeleteSpace || !this.toDeleteSpace?.id ) {
        return;
      }

      await ApiRequestService.deleteRequest( `api/admin-product-spaces/${ this.toDeleteSpace.id }` );
      this.productSpaces = this.productSpaces.filter( item => item.id !== this.toDeleteSpace.id );
      this.closeDelete();
    },

    async selectAsProductMainPhoto( photoIndex ) {
      event.stopPropagation();

      let selected = this.editedItem.photos.splice( photoIndex, 1 );

      this.editedItem.photos.forEach( item => item.isMain = false );
      selected[0].isMain = true;

      this.editedItem.photos.unshift( selected[0] );
      await ApiRequestService.postRequest( `api/facility-photos/product-photos-main/${ this.editedItem.id }`, { photoId: selected[0].id } )
    },

    openSpaceDetails( space ) {
      if ( space && space.id ) {
        let resolvedLocation = this.$router.resolve( {
          name: `space`,
          params: { spaceId: space.id }
        } )?.href;
        // StorageService.setProductToStorage( this.editedItem );
        console.log( 'resolvedLocation', resolvedLocation )
        this.$router.push( resolvedLocation );
      }
    },

    updateMainTranslations() {
      if ( this.translations['en'] ) {
        this.translations['en'].name = this.editedItem.name;
        this.translations['en'].description = this.editedItem.description;
        this.translations['en'].url = this.editedItem.url;
      }
    },

    updateTranslations( data ) {
      this.translations = data;
      this.editedItem = {
        ...this.editedItem,
        ...this.translations['en'],
      }
    },

    setTranslations() {
      this.translations = TranslationUtil.transformToRequestDto(
        this.editedItem.translations,
        {
          description: this.editedItem.description,
          name: this.editedItem.name,
          url: this.editedItem.url,
        }
      );
    },

    async getProductApi() {
      this.editedItem = await ApiRequestService.getRequest( `api/admin-products/${ this.productId }` );
      this.setTranslations();

      StorageService.setProductToStorage( this.editedItem );
      DataEmitterService.$emit( 'route-change' );

      this.sortProductPhotos();
      this.oldProduct = this.getObjClone( this.editedItem );
      this.selectedTypeId = this.editedItem.type.name;
      this.editedItem.facilities.forEach( item => {
        this.facilities.map( fa => {
          if ( fa.id === item.id ) {
            fa.selected = true
          }
        } );
        this.selectOrUnselectFacility( item.id )
      } );
    },

    async getProductTypesApi() {
      this.types = await ApiRequestService.getRequest( `api/admin-products/types` );
      this.sortTypes();
    },

    sortTypes() {
      this.types.sort( ( a, b ) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1 );
    },

    async getFacilitiesApi() {
      this.facilities = await ApiRequestService.getRequest( `api/admin-facilities/list` );
      this.facilities.forEach( item => item.selected = false );
    },

    selectType( type ) {
      let exists = this.types?.find( item => item.name === type );
      let newType = {
        name: type,
        selected: true
      }
      this.editedItem.type = exists ?? newType;
      if ( !exists ) {
        this.types.push( newType )
        this.sortTypes();
        this.selectedTypeId = type;
        this.newType = '';
      }
    },

    getToLocaleDate( date ) {
      let options = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      }
      return new Date( date ).toLocaleDateString( 'en-US', options ) ?? 'null'
    },

    selectOrUnselectFacility( id ) {
      if ( this.selectedFacilities.indexOf( id ) === -1 ) {
        this.selectedFacilities.push( id );
      } else {
        this.selectedFacilities = this.selectedFacilities.filter( item => item !== id );
      }
    },

    async getChangedProductData( oldProduct ) {
      const dataFormUpdate = {};
      for ( let [ key, value ] of Object.entries( this.editedItem ) ) {
        if ( oldProduct[key] === this.editedItem[key] ) {
          continue
        }
        if ( key === 'photos' || key === 'mapImage' ) {
          continue
        }

        if ( key === 'facilities' ) {
          let readyFacilities = [];
          for ( let [ facilityKey, facilityValue ] of Object.entries( this.selectedFacilities ) ) {
            readyFacilities.push( facilityValue );
          }
          dataFormUpdate[key] = readyFacilities;
          continue;
        }

        if ( key === 'type' ) {
          const existsType = oldProduct[key].name === this.editedItem[key].name;
          if ( !existsType ) {
            dataFormUpdate[key] = this.editedItem[key].name;
          }
          continue;
        }

        if ( key === 'translations' ) {
          dataFormUpdate[key] = this.translations;
          continue;
        }

        dataFormUpdate[key] = this.editedItem[key]
      }

      return dataFormUpdate
    },

    async saveMapSvg() {
      if ( this.uploadedMapSvgFile ) {
        const formData = new FormData();
        formData.append( "file", this.uploadedMapSvgFile, this.uploadedMapSvgFile.name );
        const mapImage = await ApiRequestService.postRequest( 'api/facility-photos/product-photos', formData );

        if ( mapImage?.id ) {
          const dataFormUpdate = { mapImageId: mapImage?.id };
          const updatedProduct = await ApiRequestService.updateRequest( `api/admin-products/${ this.editedItem.id }`, dataFormUpdate )
          if ( updatedProduct.errorMessage ) {
            return
          }

          this.editedItem = { ...this.editedItem, mapImage };
          this.$refs.mapUpload.reset();
        }
      }
    },

    async saveUploadedImages() {
      const dataFormUpdate = {};

      if ( this.editedItem?.photoIds?.length ) {
        dataFormUpdate['photoIds'] = await this.uploadProductImages( this.editedItem );
      }

      const updatedProduct = await ApiRequestService.updateRequest( `api/admin-products/${ this.editedItem.id }`, dataFormUpdate )
      if ( updatedProduct.errorMessage ) {
        return
      }

      if ( updatedProduct ) {
        this.editedItem = updatedProduct;
        this.sortProductPhotos();
      }

      this.$refs.fileupload.reset();
    },

    openCarousel( photoIndex ) {
      let carouselPhotos = this.getObjClone( this.editedItem.photos );
      carouselPhotos = ( carouselPhotos.splice( photoIndex, carouselPhotos.length ) ).concat( carouselPhotos.splice( 0, photoIndex ) );

      if ( carouselPhotos?.length ) {
        this.carouselPhotos = carouselPhotos;
        console.log( 'this.carouselPhotos', this.carouselPhotos )
        this.dialogCarousel = true;
      }
    },

    async uploadSelectedImages() {
      if ( this.currentSpace?.photoIds?.length ) {
        if ( this.currentSpace?.photoIds?.length > 10 ) {
          this.errorKey = 'Maximum number of images allowed 10';
          return;
        }
        return this.uploadProductSpaceImages( this.currentSpace );
      }
      return [];
    },

    async updateProductRequest( oldProduct ) {
      let existsPhotosCount = this.editedItem?.photos?.length;
      if ( this.editedItem?.photoIds?.length + existsPhotosCount > 10 ) {
        this.errorKey = `Maximum number of images allowed 10.You have already ${ existsPhotosCount } images`;
        return;
      }

      const dataFormUpdate = await this.getChangedProductData( oldProduct );
      const updatedProduct = await ApiRequestService.updateRequest( `api/admin-products/${ this.editedItem.id }`, dataFormUpdate )
      if ( updatedProduct.errorMessage ) {
        return
      }

      if ( updatedProduct ) {
        this.editedItem = updatedProduct;
        this.setTranslations();
        this.sortProductPhotos();
      }
    },

    urlIsValid( url ) {
      return !url || UrlUtil.isUrlValid( url );
    },

    dataIsValid() {
      const nameIsValid = this.productIsValid( this.editedItem?.name?.trim() )
      const urlIsValid = this.urlIsValid( this.editedItem?.url ) ? true : '';
      const isNotValid = []

      if ( nameIsValid !== true ) {
        isNotValid.push( nameIsValid )
      }

      if ( urlIsValid !== true ) {
        isNotValid.push( urlIsValid )
      }

      if ( isNotValid.length ) {
        return isNotValid
      }

      return true
    },


    spaceDataIsValid() {
      const nameIsValid = this.productIsValid( this.currentSpace?.name?.trim() )
      const isNotValid = []

      if ( nameIsValid !== true ) {
        isNotValid.push( nameIsValid )
      }

      if ( isNotValid.length ) {
        return isNotValid
      }

      return true
    },


    productIsValid( v ) {
      return ( v && v.length > 0 ) || this.productsSnippet.required
    },


    productWorkDaysAreValid( v ) {
      let valid = true;
      v.forEach( item => {
        if ( !item.startTime ) {
          item.startTime = '10:00:00';
        }
        if ( !item.endTime ) {
          item.endTime = '20:00:00';
        }

        if ( !this.timeRegex.test( item.startTime ) || !this.timeRegex.test( item.endTime ) ) {
          valid = 'Wrong time format';
          return;
        }
      } );
      if ( valid === true ) {
        this.globalError = true;
      }
      return valid;

    },

    async editItem( item ) {
      let paginatedList = this.searchField ? this.paginatedSearchedProductsList : this.paginatedProductsList;
      let list = this.searchField ? this.searchedProductsList : this.productsList;
      this.editedIndex = paginatedList.findIndex( fc => fc.id === item.id );
      this.listEditedIndex = list.findIndex( fc => fc.id === item.id );
      this.editedItem = this.getObjClone( paginatedList[this.editedIndex] );

      this.dialog = true
      this.disableKeyField = true
      this.oldProduct = this.getObjClone( this.editedItem.product )
    },

    deleteSpaceItem( item ) {
      let toDeleteIndex = this.productSpaces.findIndex( fs => fs.id === item.id );
      this.toDeleteSpace = this.getObjClone( this.productSpaces[toDeleteIndex] );
      this.dialogDeleteSpace = true
    },

    upgradeItem( item ) {
      this.editedIndex = this.productsList.indexOf( item )
      this.dialogUpgrade = true
    },

    getObjClone( obj ) {
      return JSON.parse( JSON.stringify( obj ) );
    },

    closeDelete() {
      this.dialogDelete = false;
      this.dialogDeleteSpace = false;
      this.dialogDeletePhoto = false;
      this.submitted = false;
    },

    closeDialogCarousel() {
      this.dialogCarousel = false;
      this.carouselPhotos = {}
    },

    closeDeleteEvent() {
      this.deleteEventDialog = false;
    },

    closeUpgrade() {
      this.dialogUpgrade = false;
      this.submitted = false;
      this.$nextTick( () => {
        this.editedItem = { ...this.getObjClone( this.defaultItem ) };
        this.editedIndex = -1;
      } )
    },

    generateRandomUniqueString( len ) {
      let text = '';
      for ( let i = 0; i < len; i++ ) {
        text += Math.random().toString( 36 ).slice( 2 ) + Date.now()
      }

      return text
    },


    async uploadProductImages( dataToCreate ) {
      let photoIds = [];

      for ( let i = 0; i < dataToCreate?.photoIds?.length; i++ ) {
        const formData = new FormData();
        formData.append( "file", dataToCreate?.photoIds[i], dataToCreate?.photoIds[i].name );

        let data = await ApiRequestService.postRequest( 'api/facility-photos/product-photos', formData );
        photoIds.push( data?.id );
      }

      return photoIds;
    },
    async uploadProductSpaceImages( dataToCreate ) {
      let photoIds = [];

      for ( let i = 0; i < dataToCreate?.photoIds?.length; i++ ) {
        const formData = new FormData();
        formData.append( "file", dataToCreate?.photoIds[i], dataToCreate?.photoIds[i].name );

        let data = await ApiRequestService.postRequest( 'api/product-photos/product-space-photos', formData );
        photoIds.push( data?.id );
      }

      return photoIds;
    },

    async updateProduct() {
      let isValid = this.dataIsValid();
      if ( isValid !== true ) {
        this.globalError = isValid;
        console.log( 'this.globalError', this.globalError )
        return;
      }

      await this.updateProductRequest( this.oldProduct );
    },

    async save() {
      this.submitted = true;
      await this.updateProduct();
    },

    setPhotos( files ) {
      if ( files && files.length ) {
        if ( files.length + this.editedItem.photos.length > 10 ) {
          this.errorKey = 'Is not able to upload more then 10 images';
          this.$refs.fileupload.reset();
        } else {
          this.errorKey = '';
          for ( let file of files ) {
            if ( file.size / 1024 / 1024 > 3 ) {
              this.errorKey = 'Image size should be less then 3mb.';
              this.$refs.fileupload.reset();
              return
            }
          }
          this.editedItem.photoIds = files;
        }
      }
    },

    setMapSvg( file ) {
      if ( file ) {
        this.uploadedMapSvgFile = file;
      }
    },

    sortProductPhotos() {
      let mainPhotoIndex = this.editedItem.photos.findIndex( ( f ) => f.isMain );
      let mainPhoto = this.editedItem.photos.splice( mainPhotoIndex, 1 )[0];
      if ( mainPhoto ) {
        this.editedItem.photos.unshift( mainPhoto );
      }
    },

  }
}

